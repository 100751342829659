/* CSS */
.button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }
  
  .button-3:hover {
    background-color: #2c974b;
  }
  
  .button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
  }
  
  .button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
  }
  
  .button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
  }

  /* Youtube video embedding */

  .video-media-youtube {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
    
  .video__container {
    display: block;
    width: 100%; 
    padding: 0 0;
    margin: 0 auto;
  }

  .video-media-youtube-inner-vi {
    display: block;
    overflow: hidden;
    position: relative;
    height: 0;
    padding: 0;
  }
  .video-media-youtube-inner-vi--ratio {
    padding-bottom: 65%;
  }
  .video-media-youtube-inner-vi iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: 1024px) {
    .video__container {
      padding: 0 1.25rem;
    }
    
    .video-media-youtube-inner {
      justify-content: space-between;
    }
    
    .media__embed {
      flex-basis: calc(50% - .9375rem);
      padding-bottom: 1.875rem;
    }
  }